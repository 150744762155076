import DataTable from 'react-data-table-component';
import React, {useEffect, useState, useCallback} from 'react';

import '../Groups/styles.css';
import {API} from "../../api"
import Swal from "sweetalert2";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const api = new API()

export const Copy = ({text}) => {
  return (<CopyToClipboard text={text}
                           onCopy={() => Swal.fire({
                             position: 'top-end',
                             icon: 'success',
                             title: 'Copied to clipboard',
                             showConfirmButton: false,
                             timer: 1000
                           })}>
    <div><span style={{display:'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 200, whiteSpace:'nowrap'}}>{text}</span>  <span style={{display:'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', width: 20, whiteSpace:'nowrap'}}>📋</span></div>
  </CopyToClipboard>)
}

export const DeletedGroupsScreen = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(200);
  const [sorting, setSorting] = useState('-lastActivity');
  const [page] = useState(1);

  const fetchGroups = useCallback(async (page, sort) => {
    try {
      setLoading(true);

      const response = await api.get(`groups/deleted?page=${page}&limit=${perPage}&sort=${sort}`);

      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);
    } catch (e) {
      setTimeout(async () => fetchGroups(page), 500)
    }
  }, [perPage]);

  const handlePageChange = page => {
    fetchGroups(page, sorting);
  };

  const handleSort = async (column, sortDirection) => {
    const ord = sortDirection==='desc'?'-':''
    setSorting(`${ord}${column.id}`)
    fetchGroups(page, `${ord}${column.id}`)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchGroups(1, sorting);

  }, [fetchGroups, sorting]);


  return (
    <div>

      <div className="main-center-container">
        <div style={styles.groupsContainer}>
          <>
            <div style={styles.rightContainerHeader}>
              <span>GROUPS</span>
              <span className="rightContainerHeader-sub">Sorted by last activity</span>
            </div>
          </>
          <div
            id={'tbl'}>
            <DataTable
              theme="dark"
              columns={[
                {
                  id: 'name',
                  name: 'Name',
                  selector: row => row.data.group.name,
                },
                {
                  id: 'members',
                  name: 'Members',
                  selector: row => row.data.group.chatType!=='DM'?row.data.group.members.length:row.data.group.chatType
                },
                {
                  id: 'count',
                  name: 'Messages',
                  selector: row => row.data.counters?.[0]?.count,
                },
                {
                  id: 'lastActivity',
                  name: 'Last Activity',
                  selector: row => new Date((row.data.group.lastActivity || row.data.group.updatedAt)).toString().split(' ').splice(0, 5).join(' ').slice(0, -3),
                },
                {
                  id: 'createdAt',
                  name: 'Created At',
                  selector: row => new Date((row.data.group.createdAt)).toString().split(' ').splice(0, 4).join(' '),
                },
                {
                  id: 'deletedAt',
                  name: 'Deleted At',
                  sortable: false,
                  selector: row => new Date((row.data.deletedAt)).toString().split(' ').splice(0, 4).join(' '),
                },
              ]}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationPerPage={200}
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10,50,100,200]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}

              onSort={handleSort}
              sortServer
              defaultSortFieldId={'lastActivity'}
              defaultSortAsc={false}
            />


          </div>
        </div>



      </div>
    </div>
  )
};

const styles = {

  groupsContainer: {
    padding: 10,
    backgroundColor: '#191C22',
    borderRadius: 25,
    width: '100%'
  },
  rightContainerHeader: {
    color: '#fefefe',
    padding: 20,
    paddingLeft: 15,
    fontSize: 15,
    fontWeight: 700,
    letterSpacing: 2,
    borderBottom: '1px solid rgb(40 43 47)',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },
  menuContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  menuItemText: {
    color: '#fefefe'
  }
};

