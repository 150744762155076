import DataTable from 'react-data-table-component';
import React, {useEffect, useState, useCallback} from 'react';

import '../Groups/styles.css';
import {API} from "../../api"
import Swal from "sweetalert2";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {Button} from "antd";

const api = new API()

export const Copy = ({text}) => {
  return (<CopyToClipboard text={text}
                           onCopy={() => Swal.fire({
                             position: 'top-end',
                             icon: 'success',
                             title: 'Copied to clipboard',
                             showConfirmButton: false,
                             timer: 1000
                           })}>
    <div><span style={{display:'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 200, whiteSpace:'nowrap'}}>{text}</span>  <span style={{display:'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', width: 20, whiteSpace:'nowrap'}}>📋</span></div>
  </CopyToClipboard>)
}

export const PhoneNumbers = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [sorting, setSorting] = useState('-createdAt');
  const [page] = useState(1);

  const fetchGroups = useCallback(async (page, sort) => {
    try {
      setLoading(true);

      const response = await api.get(`numbers?page=${page}&limit=${perPage}&sort=${sort}`);

      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);
    } catch (e) {
      setTimeout(async () => fetchGroups(page), 500)
    }
  }, [perPage]);

  const handlePageChange = page => {
    fetchGroups(page, sorting);
  };

  const handleSort = async (column, sortDirection) => {
    const ord = sortDirection==='desc'?'-':''
    setSorting(`${ord}${column.id}`)
    fetchGroups(page, `${ord}${column.id}`)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const openCreateForm = async () => {
    setLoading(true);

    Swal.fire({
      title: 'Create new number',
      text: 'Use the mask 555#######',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Create',
      showLoaderOnConfirm: true,
      preConfirm: async (phone_number) => {
        return await api.post('numbers', {phone_number}).then((res)=>{
          if(!res.uuid) {
            Swal.showValidationMessage(
              `Number format is incorrect or is already existing`
            )
            return false;
          } else {
            return res;
          }
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Number successfully created!`,
          text: result.phone_number
        })
        fetchGroups(1, sorting);
      }
    })


    setLoading(false);
  };

  useEffect(() => {
    fetchGroups(1, sorting);

  }, [fetchGroups, sorting]);


  return (
    <div>

      <div className="main-center-container">
        <div style={styles.groupsContainer}>
          <>
            <div style={styles.rightContainerHeader}>
              <span>Phone Numbers</span>
              <div style={{float:'right'}}>
                <Button type="primary" onClick={openCreateForm}>Create</Button>
              </div>
            </div>
          </>
          <div
            id={'tbl'}>
            <DataTable
              theme="dark"
              columns={[
                {
                  id: 'phone_number',
                  name: 'Number',
                  selector: row => row.phone_number,
                },
                {
                  id: 'users',
                  name: 'Used by',
                  sortable: false,
                  selector: row => row.usedBy,
                },
                {
                  id: 'createdAt',
                  name: 'Created At',
                  selector: row => new Date((row.createdAt)).toString().split(' ').splice(0, 4).join(' '),
                }
              ]}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationPerPage={200}
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10,50,100,200]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}

              onSort={handleSort}
              sortServer
              defaultSortFieldId={'lastActivity'}
              defaultSortAsc={false}
            />


          </div>
        </div>



      </div>
    </div>
  )
};

const styles = {

  groupsContainer: {
    padding: 10,
    backgroundColor: '#191C22',
    borderRadius: 25,
    width: '100%'
  },
  rightContainerHeader: {
    color: '#fefefe',
    padding: 20,
    paddingLeft: 15,
    fontSize: 15,
    fontWeight: 700,
    letterSpacing: 2,
    borderBottom: '1px solid rgb(40 43 47)',
  },
  menuContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  menuItemText: {
    color: '#fefefe'
  }
};

