import React, {useEffect, useState, useCallback} from 'react';

import './styles.css';
import {API} from "../../api"
import {Select, Spin, Statistic, Skeleton, Alert} from 'antd';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(CategoryScale)
ChartJS.register(LinearScale)
ChartJS.register(PointElement)
ChartJS.register(LineElement)
ChartJS.register(Tooltip)

const periodFilters = [
  1,
  7,
  30
]

const api = new API()

export const DashboardScreen = () => {


  const [stats, setStats] = useState({});
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);

  const PeriodComponent = ({filterName}) => {
    return (
      <Select
        size={'small'}
        defaultValue={filters[filterName] || 7}
        style={{width: 120}}
        onChange={(v) => {
          setFilters({...filters, [filterName]: v})
        }}
        options={periodFilters.map(pf => ({
          value: pf,
          label: `${pf} day${pf > 1 ? 's' : ''}`
        }))}
      />
    );
  }

  const formatDate = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const fetchStats = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post(`groups/stats`, filters);

      const usersData = response.newUsersDaily;

      const firstDate = new Date(usersData[0]._id);
      const lastDate = new Date(usersData[usersData.length - 1]._id);


      usersData.sort((a, b) => (a._id > b._id ? 1 : -1));

      const weeklyData = [];
      const weeklyDAU = [];
      const weeklyMD = [];
      let weekStartDate;
      let weekEndDate;
      let weeklyCount = 0;
      let weeklyCountDAU = 0;
      let weeklyCountMD = 0;

      let currentDate = new Date(firstDate);
      let i = 0;

      while (currentDate <= lastDate && i < 1000) {
        const fDate = formatDate(currentDate);
        const user = usersData.find(d=>formatDate(new Date(d._id))===fDate) || {_id: fDate, count: 0}
        const curDAU = response.dau.find(d=>formatDate(new Date(d._id))===fDate) || {_id: fDate, count: 0}
        const curMD = response.messagesDaily.find(d=>formatDate(new Date(d._id))===fDate) || {_id: fDate, count: 0}
        console.log(curDAU)

        i++;
        currentDate = new Date(user._id);
        const dayOfWeek = currentDate.getUTCDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday

        if (dayOfWeek === 0) {
          // Step 3: Calculate the start and end date for each week (Sunday to next Sunday)
          if (weekStartDate && weekEndDate) {
            weeklyData.push({
              _id: `${weekStartDate.toISOString().slice(0, 10)} - ${weekEndDate.toISOString().slice(0, 10)}`,
              count: weeklyCount,
            });
            weeklyDAU.push({
              _id: `${weekStartDate.toISOString().slice(0, 10)} - ${weekEndDate.toISOString().slice(0, 10)}`,
              count: Math.floor(weeklyCountDAU/7),
            });
            weeklyMD.push({
              _id: `${weekStartDate.toISOString().slice(0, 10)} - ${weekEndDate.toISOString().slice(0, 10)}`,
              count: Math.floor(weeklyCountMD/7),
            });
          }

          weekStartDate = new Date(currentDate);
          weeklyCount = 0;
          weeklyCountDAU = 0;
          weeklyCountMD = 0;
        }

        weeklyCount += user.count;
        weeklyCountDAU += curDAU.count;
        weeklyCountMD += curMD.count;
        weekEndDate = new Date(currentDate);
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      }

      // Step 5: Add the last week to the weeklyData array
      if (weekStartDate && weekEndDate) {
        weeklyData.push({
          _id: `${weekStartDate.toISOString().slice(0, 10)} - ${weekEndDate.toISOString().slice(0, 10)}`,
          count: weeklyCount,
        });
        weeklyDAU.push({
          _id: `${weekStartDate.toISOString().slice(0, 10)} - ${weekEndDate.toISOString().slice(0, 10)}`,
          count:  Math.floor(weeklyCountDAU/7),
        });
        weeklyMD.push({
          _id: `${weekStartDate.toISOString().slice(0, 10)} - ${weekEndDate.toISOString().slice(0, 10)}`,
          count:  Math.floor(weeklyCountMD/7),
        });
      }

      response.newUsersWeekly = weeklyData;
      response.DAUWeekly = weeklyDAU;
      response.MDWeekly = weeklyMD;
      console.log(weeklyMD)
      setStats(response);
      setLoading(false);
    } catch (e) {
      console.log(e)
      setTimeout(fetchStats, 500);
    }
  }, [filters])


  useEffect(() => {
    fetchStats();
  }, [fetchStats]);


  return (
    <div style={{marginTop: -30}}>
      <div style={{height: 0, textAlign: 'center'}}>
        {loading && <Spin size="large" tip="Loading" style={{marginTop: 4}}/>}
      </div>
      <div className="stats-container">
        <div className="stats-header-container">
          <span className="stats-header-text">STATISTICS</span>
        </div>
        <div className="stats-inner-container">
          <div className="stats-item-sub-container">
            <div className="borderContainer blue"/>
            <div className="stats-item-container no-border">
              <span className="stats-number"><Statistic valueStyle={{color: 'white'}} loading={loading}
                                                        value={stats.totalGroups}/></span>
              <span className="stats-header">Groups{"\n"}Total</span>
              <span className="stats-sub-header">All Time</span>
            </div>
          </div>
          <div className="stats-item-sub-container">
            <div className="borderContainer red"/>
            <div className="stats-item-container">
              <span
                className="stats-number"><Statistic valueStyle={{color: 'white'}} loading={loading}
                                                    value={stats.last7Created}/></span>
              <span className="stats-header">Groups Created</span>
              <span className="stats-sub-header"><PeriodComponent filterName={'last7Created'}/></span>
            </div>
          </div>
          <div className="stats-item-sub-container">
            <div className="borderContainer yellow"/>
            <div className="stats-item-container yellow">
              <span
                className="stats-number"><Statistic valueStyle={{color: 'white'}} loading={loading}
                                                    value={stats.last7Active}/></span>
              <span className="stats-header">Groups Active</span>
              <span className="stats-sub-header"><PeriodComponent filterName={'last7Active'}/></span>
            </div>
          </div>
        </div>
      </div>

      <div className="stats-container">
        <div className="stats-header-container">
          <span className="stats-header-text">CHARTS</span>
        </div>
        {!stats.mau && <div className="stats-inner-container" style={{padding: 40}}><Skeleton active/></div>}
        {stats.mau &&
          <div className="stats-inner-container" style={{height: 'calc(100vh - 360px)', overflowY: 'scroll'}}>
            <div style={{width: '100%'}}>
              <Alert message="Note: ignore last month/day as it's not complete data" type="error"/>
              <div style={{margin: 'auto', padding: 20, width: '100%'}}>
                <Alert message={<strong>Monthly Sending Users</strong>} type="info"/>
                <Line data={
                  {
                    labels: stats.mau.map(d => d._id),
                    datasets: [{
                      label: 'MAU',
                      backgroundColor: 'rgb(255, 99, 132)',
                      borderColor: 'rgb(255, 99, 132)',
                      data: stats.mau.map(d => d.count),
                    }]
                  }
                } options={{
                  cubicInterpolationMode: 'monotone',
                  responsive: true,
                  aspectRatio: 3,
                  scales: {
                    xAxes: [{
                      type: 'time',
                      position: 'bottom',
                      time: {
                        displayFormats: {'day': 'MM/YY'},
                        tooltipFormat: 'MM/YY',
                        unit: 'month',
                      }
                    }]
                  }
                }}/>
              </div>

              <div style={{margin: 'auto', padding: 20, width: '100%'}}>

                <Alert message={<strong>Daily Sending Users</strong>} type="info"/>
                <Line data={
                  {
                    labels: stats.dau.map(d => d._id),
                    datasets: [{
                      label: 'DAU',
                      backgroundColor: 'rgb(255, 99, 132)',
                      borderColor: 'rgb(255, 99, 132)',
                      data: stats.dau.map(d => d.count),
                    }]
                  }
                } options={{
                  cubicInterpolationMode: 'monotone',
                  fill: true,
                  responsive: true,
                  aspectRatio: 2.5,
                  scales: {
                    xAxes: [{
                      type: 'time',
                      position: 'bottom',
                      time: {
                        displayFormats: {'day': 'MM/YY'},
                        tooltipFormat: 'DD/MM/YY',
                        unit: 'month',
                      }
                    }]
                  }
                }}/>
              </div>

              <div style={{margin: 'auto', padding: 20, width: '100%'}}>

                <Alert message={<strong>Messages Daily</strong>} type="info"/>
                <Line data={
                  {
                    labels: stats.messagesDaily.map(d => d._id),
                    datasets: [{
                      label: 'Messages Daily',
                      backgroundColor: 'rgb(255, 99, 132)',
                      borderColor: 'rgb(255, 99, 132)',
                      data: stats.messagesDaily.map(d => d.count),
                    }]
                  }
                } options={{
                  cubicInterpolationMode: 'monotone',
                  fill: true,
                  responsive: true,
                  aspectRatio: 2.5,
                  scales: {
                    xAxes: [{
                      type: 'time',
                      position: 'bottom',
                      time: {
                        displayFormats: {'day': 'MM/YY'},
                        tooltipFormat: 'DD/MM/YY',
                        unit: 'month',
                      }
                    }]
                  }
                }}/>
              </div>
              <div style={{margin: 'auto', padding: 20, width: '100%'}}>

                <Alert message={<strong>Daily Messages (weekly average)</strong>} type="info"/>
                <Line data={
                  {
                    labels: stats.MDWeekly.map(d => d._id),
                    datasets: [{
                      label: 'Messages Daily(avg)',
                      backgroundColor: 'rgb(255, 99, 132)',
                      borderColor: 'rgb(255, 99, 132)',
                      data: stats.MDWeekly.map(d => d.count),
                    }]
                  }
                } options={{
                  cubicInterpolationMode: 'monotone',
                  fill: true,
                  responsive: true,
                  aspectRatio: 2.5,
                  scales: {
                    xAxes: [{
                      type: 'time',
                      position: 'bottom',
                      time: {
                        displayFormats: {'day': 'MM/YY'},
                        tooltipFormat: 'DD/MM/YY',
                        unit: 'month',
                      }
                    }]
                  }
                }}/>
              </div>

              <div style={{margin: 'auto', padding: 20, width: '100%'}}>

                <Alert message={<strong>New Users Weekly</strong>} type="info"/>
                <Line data={
                  {
                    labels: stats.newUsersWeekly.map(d => d._id),
                    datasets: [{
                      label: 'New Users Weekly',
                      backgroundColor: 'rgb(255, 99, 132)',
                      borderColor: 'rgb(255, 99, 132)',
                      data: stats.newUsersWeekly.map(d => d.count),
                    }]
                  }
                } options={{
                  cubicInterpolationMode: 'monotone',
                  fill: true,
                  responsive: true,
                  aspectRatio: 2.5,
                  scales: {
                    xAxes: [{
                      type: 'time',
                      position: 'bottom',
                      time: {
                        displayFormats: {'day': 'MM/YY'},
                        tooltipFormat: 'DD/MM/YY',
                        unit: 'month',
                      }
                    }]
                  }
                }}/>
              </div>
              <div style={{margin: 'auto', padding: 20, width: '100%'}}>

                <Alert message={<strong>Daily Sending Users (weekly average)</strong>} type="info"/>
                <Line data={
                  {
                    labels: stats.DAUWeekly.map(d => d._id),
                    datasets: [{
                      label: 'DAU',
                      backgroundColor: 'rgb(255, 99, 132)',
                      borderColor: 'rgb(255, 99, 132)',
                      data: stats.DAUWeekly.map(d => d.count),
                    }]
                  }
                } options={{
                  cubicInterpolationMode: 'monotone',
                  fill: true,
                  responsive: true,
                  aspectRatio: 2.5,
                  scales: {
                    xAxes: [{
                      type: 'time',
                      position: 'bottom',
                      time: {
                        displayFormats: {'day': 'MM/YY'},
                        tooltipFormat: 'DD/MM/YY',
                        unit: 'month',
                      }
                    }]
                  }
                }}/>
              </div>
              <div style={{margin: 'auto', padding: 20, width: '100%'}}>

              <Alert message={<strong>New Users Daily</strong>} type="info"/>
              <Line data={
                {
                  labels: stats.newUsersDaily.map(d => d._id),
                  datasets: [{
                    label: 'New Users Daily',
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgb(255, 99, 132)',
                    data: stats.newUsersDaily.map(d => d.count),
                  }]
                }
              } options={{
                cubicInterpolationMode: 'monotone',
                fill: true,
                responsive: true,
                aspectRatio: 2.5,
                scales: {
                  xAxes: [{
                    type: 'time',
                    position: 'bottom',
                    time: {
                      displayFormats: {'day': 'MM/YY'},
                      tooltipFormat: 'DD/MM/YY',
                      unit: 'month',
                    }
                  }]
                }
              }}/>
            </div>

            </div>
          </div>}
      </div>


      <div className="main-center-container">


      </div>
    </div>
  )
};
