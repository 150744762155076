import React, {useState} from "react";
import {
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import {useAuth} from "./useAuth.js";
import {Groups} from "./screens/Groups";
import {DeletedGroups} from "./screens/DeletedGroups";
import {Dashboard} from "./screens/Dashboard";
import LowkeyLogo from "./screens/Groups/lowkey_team.png";
import {DeploymentUnitOutlined, LogoutOutlined, LineChartOutlined, DeleteOutlined, PhoneOutlined } from "@ant-design/icons";
import './screens/Groups/styles.css';
import { Card } from 'antd';
import Swal from 'sweetalert2'

import 'antd/dist/antd.css';
import Search from "antd/es/input/Search";
import {deleteCookie} from "./cookies";
import {PhoneNumbers} from "./screens/PhoneNumbers/PhoneNumbers.screen";


const styles = {
  mainContainer: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#050608',
    display: 'flex',
    flexDirection: 'row',
  },

  headerContainer: {
    width: 300,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    borderRight: '1px solid rgb(40 43 47)',
    flexDirection: 'column',
  },
  headerTextContainer: {
    width: '100%',
    padding: 20,
    paddingLeft: 40,
    borderBottom: '1px solid rgb(40 43 47)',
    display: 'flex',
    alignItems: 'center',
  },
  headerLogo: {
    width: 30,
  },
  headerText: {
    marginLeft: 10,
    color: '#fefefe',
    fontSize: 20,
    fontWeight: 500,
  },
  rightContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    padding: 40,
    color: '#fefefe',
  },
  groupsContainer: {
    padding: 10,
    backgroundColor: '#191C22',
    borderRadius: 25,
    width: '100%'
  },
  rightContainerHeader: {
    color: '#fefefe',
    padding: 20,
    paddingLeft: 15,
    fontSize: 15,
    fontWeight: 700,
    letterSpacing: 2,
    borderBottom: '1px solid rgb(40 43 47)',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },
  menuContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  loginButton: {
    color: 'black'
  },
};

const menuItems = [
  {
    label: 'Dashboard',
    icon: <LineChartOutlined  className="menuItemText-icon"/>,
    route: '/dashboard'
  },
  {
    label: 'Groups',
    icon: <DeploymentUnitOutlined  className="menuItemText-icon"/>,
    route: '/groups'
  },
  {
    label: 'Deleted Groups',
    icon: <DeleteOutlined  className="menuItemText-icon"/>,
    route: '/deleted-groups'
  },
  {
    label: 'Phone Numbers',
    icon: <PhoneOutlined  className="menuItemText-icon"/>,
    route: '/phone-numbers'
  },
];

const PrivateRoute = ({children, ...rest}) => {

  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({location}) =>
        auth.user!==false ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {from: location}
            }}
          />
        )
      }
    />
  );
}


export const App = () => {

  const [inputs, setInputs] = useState({});
  const [codeSent, setCodeSent] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();


  const PublicPage = () => {
    return <h3>Public</h3>;
  }

  const LoginPage = () => {
    if(auth.user && location.pathname === '/login'){
      return (<Redirect
        to={{
          pathname: "/dashboard",
          state: {from: location}
        }}
      />)
    }
    let {from} = location.state || {from: {pathname: "/"}};
    let login = (code) => {
      auth.signin(inputs.email, code, () => {
        history.replace(from);
        document.location.reload()
      });
    };
    let sendCode = (email) => {
      if(email && String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
        setInputs({...inputs, email})
        setCodeSent(true)
        setTimeout(() => setCodeSent(false), 60000)
        auth.sendCode(email, (res) => {
          if(!res){
            setCodeSent(false)
          }
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Please provide correct email',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    };

    return auth.user?(<h3>Hello, {auth.user.name}</h3>):(
      <div className="site-card-border-less-wrapper">
        <Card title="Sign In" bordered={false} style={{ minWidth: 360, width: '40%', margin: 'auto' }}>
          <p>You must log in to view the page at {from.pathname}</p>
          <Search
            placeholder="Your email address"
            type={'email'}
            enterButton="Send Code"
            size="large"
            disabled={codeSent}
            onSearch={sendCode}
          />
          {(codeSent || inputs.code) && <div>
            <hr/>
            <Search
              placeholder="Code from email"
              type={'number'}
              enterButton="Submit"
              size="large"
              onSearch={login}
            />
          </div>}

        </Card>
      </div>
    );
  }


  return (


      <div style={styles.mainContainer} className="groups-screen">
        <div style={styles.headerContainer}>
          <div style={styles.headerTextContainer}>
            <img src={LowkeyLogo} style={styles.headerLogo} alt={''}/>
            <span style={styles.headerText}>Lowkey</span>
          </div>
          <div style={styles.menuContainer}>
            {menuItems.map(menuItem => (
                <Link to={menuItem.route} key={menuItem.route} >
                  <div style={styles.menuItemContainer} className={`menuItemContainer `+(location.pathname.indexOf(menuItem.route)!==-1?'selected':'')}>
                    {menuItem.icon}
                    <span style={styles.menuItemText}> {menuItem.label}</span>
                  </div>
                </Link>
              )
            )}
            {auth.user && <div style={styles.menuItemContainer} className={`menuItemContainer`}>
              <LogoutOutlined className="menuItemText-icon"/>
              <a href={'#exit'} onClick={()=>{
                deleteCookie('d-token')
                document.location.reload()
              }}> <span style={styles.menuItemText}>Exit</span></a>
            </div>}
          </div>
        </div>
        <div style={styles.rightContainer}>

          <Switch>
            <Route path="/public">
              <PublicPage/>
            </Route>
            <Route path="/login">
              <LoginPage/>
            </Route>
            <PrivateRoute path="/dashboard">
              <Dashboard/>
            </PrivateRoute>
            <PrivateRoute path="/groups">
              <Groups/>
            </PrivateRoute>
            <PrivateRoute path="/deleted-groups">
              <DeletedGroups/>
            </PrivateRoute>
            <PrivateRoute path="/phone-numbers">
              <PhoneNumbers/>
            </PrivateRoute>
            <Redirect from='*' to='/dashboard' />
          </Switch>
        </div>


      </div>
  );
}


