import React, { useState, useEffect, useContext, createContext } from "react";
import {API} from "./api"
import Swal from "sweetalert2";
import {setCookie} from "./cookies";

const api = new API()

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = async (email, code, cb) => {
    const user = await api.put('admins', {email, code}).catch(e=> {
      Swal.fire({
        title: 'Error!',
        text: 'Code is invalid',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })
    if(user) {
      setUser(user)
      setCookie('d-token',user.token)
      cb && cb()
    } else {
      setUser(false)
    }
  };
  const sendCode = async (email, cb) => {
    const res = await api.post('admins', {email}).catch(e=>{
      Swal.fire({
        title: 'Error!',
        text: 'Please provide correct email',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })

    cb && cb(res)
  };
  const fetchUser = async () => {
    const user = await api.get('admins/me')
    if(user) {
      setUser(user)
    } else {
      setUser(false)
    }
  };
  const signup = (email, code) => {

  };
  const signout = () => {

  };
  const sendPasswordResetEmail = (email) => {

  };
  const confirmPasswordReset = (code, password) => {

  };

  useEffect(( ) => {
   fetchUser()
  }, []);
  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    sendCode,
  };
}
