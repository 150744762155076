import React, {useCallback, useEffect, useState} from "react";
import ReactLoading from "react-loading";
import {Button, Timeline} from "antd";
import {API} from "../api"

const api = new API()

export const ExpandedGroupComponent = ({data}) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const fetchGroup = useCallback(async () => {
    setLoading(true);

    const response = await api.get(`messages/${data._id}?page=${page}&limit=100&sort=-createdAt`);

    setHistory(response);
    setLoading(false);
  }, [data._id, page]);

  const loadMore = async () => {
    setLoading(true);
    setPage(page+1)
    const response = await api.get(`messages/${data._id}?page=${page+1}&limit=100&sort=-createdAt`);

    setHistory([...history, ...response]);
    setLoading(false);
  }

  useEffect(() => {
    fetchGroup(); // fetch page 1 of users

  }, [fetchGroup]);

  const showNotificationLabel = (data) => {
    if(data.attachments?.[0]?.type==='USER_JOINED') {
      return `${data.text} (${data.attachments?.[0]?.attachmentData?.from || '---'})`;
    }
    else
      return data.text;
  }

  return <div>

    <div className="main-center-right-container" >
      <div style={{
        paddingRight: 40, maxHeight: '60vh', paddingTop: 30,
        overflow: 'auto',
      }}>
        <Timeline mode={'left'}>
          {history.map(_ => {
            let data;
            try {
              data = JSON.parse(_.data);
            } catch (e) {
              data = _.data;
            }
            return (
              <Timeline.Item key={_.id} label={new Date(_.createdAt).toLocaleDateString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
              })}>
                {_.type === 'V2_MESSAGE' ?
                  <>
                    {`${_.sender.name} has ${_.metaData?.extraData?.fromShare?`shared`:`sent`} a ${(_.metaData && _.metaData?.attachments.length > 0) ? `${_.metaData.attachments[0].type === 'IMAGE' ? _.metaData.attachments[0].secret ? 'SELF-DESTRUCTIVE' : 'IMAGE' : _.metaData.attachments[0].type || 'message'}${_.metaData.attachments[0].secret ? ' (Anonymous)' : ' (Public)'}` : 'message'}`}
                    <p style={{fontSize: 10}}>{`(v: ${_.encryptionVersion} | pts: ${_.pts})`}{_.commentsAmount?` (Thread: ${_.commentsAmount})`:''}</p>
                  </>
                  :
                  (data.attachments && data.attachments.length > 0 && data.text === '' ?
                      `${_.sender.name} has sent a ${data.attachments[0].type}`
                      :
                      _.type === "BACKGROUND_UPDATE/SHARED_SECRET" ? 'Secret key exchange' : showNotificationLabel(data)
                  )
                }
                {
                  _.metaData.extraData && _.metaData.extraData.sayHello && ` (Say hello with ${_.metaData.extraData.sayHello})`
                }
                {_.likes.length > 0 &&
                  <div style={{padding: 5, borderWidth: 1, borderColor: '#999', fontSize: 10}}>
                    <span>🔥 {_.likes.length}</span>
                  </div>
                }
              </Timeline.Item>
            )
          })}
        </Timeline>
        {loading && <div style={{width: '100%', display: 'flex', justifyContent: 'center', padding: 20}}>
          <ReactLoading type={'spinningBubbles'} color={'white'} height={'30px'}/>
        </div>}
        <Button type="primary" block onClick={loadMore}>
          Load more...
        </Button>
      </div>
    </div>


  </div>
}
