import _ from 'lodash';
import DataTable from 'react-data-table-component';
import React, {useEffect, useState, useCallback} from 'react';

import './styles.css';
import {API} from "../../api"
import {ExpandedGroupComponent} from "../../components/GroupMessages";
import Swal from "sweetalert2";
import { Table, Tag } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const api = new API()

export const Copy = ({text}) => {
  return (<CopyToClipboard text={text}
                           onCopy={() => Swal.fire({
                             position: 'top-end',
                             icon: 'success',
                             title: 'Copied to clipboard',
                             showConfirmButton: false,
                             timer: 1000
                           })}>
    <div><span style={{display:'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 200, whiteSpace:'nowrap'}}>{text}</span>  <span style={{display:'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', width: 20, whiteSpace:'nowrap'}}>📋</span></div>
  </CopyToClipboard>)
}

export const GroupsScreen = () => {

  const [query, setQuery] = useState('');
  const [groupQuery, setGroupQuery] = useState('');

  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setLoading2] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(200);
  const [sorting, setSorting] = useState('-lastActivity');
  const [page] = useState(1);

  const fetchGroups = useCallback(async (page, sort) => {
    try {
      setLoading(true);

      const response = await api.get(`groups?page=${page}&limit=${perPage}&sort=${sort}&name=${groupQuery}`);

      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);
    } catch (e) {
      setTimeout(async () => await fetchGroups(page), 500)
    }
  }, [perPage, groupQuery]);

  const groupQueryHandler = (e) => {
    setGroupQuery(e.target.value )
  };
  const handlePageChange = page => {
    fetchGroups(page, sorting);
  };

  const handleSort = async (column, sortDirection) => {
    const ord = sortDirection==='desc'?'-':''
    setSorting(`${ord}${column.id}`)
    fetchGroups(page, `${ord}${column.id}`)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchGroups(1, sorting);

  }, [fetchGroups, sorting]);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    setLoading2(true)
    if(event.target.value.length>0)
      fetchUsers(event.target.value)
  }


  const inputProps = {
    className: "users_input",
    placeholder: 'Start typing user name, phone or ID.',
    value: query,
    onChange: handleQueryChange
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUsers = React.useCallback(_.debounce(async (query) => {

    try {

      const response = await api.get(`users?q=${query}`);

      setUsers(response);

    } catch (e) {
     console.log(e)
    }

    setLoading2(false)
  }, 1000), [])


  return (
    <div>
      <div className="stats-container display-none">
        <div className="stats-header-container">
          <span className="stats-header-text">USERS</span>
        </div>
        <div className="stats-inner-container" style={{flexDirection: 'column'}}>
          <input {...inputProps} />
          {users && (<div style={{backgroundColor: '', padding: 8}}>
            <Table

              columns={[
              {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                render: text => <Copy text={text}/>,
              },
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: text => <Copy text={text}/>,
              },
              {
                title: 'Public Key',
                dataIndex: 'publicKey',
                key: 'publicKey',
                render: text => <Copy text={text}/>,
              },

              {
                title: 'Groups',
                key: 'groups',
                dataIndex: 'groups',
                render: (_, { groups=[] }) => (
                  <div style={{maxWidth: 250}}>
                    {groups.map(group => {
                      let color = 'geekblue';

                      return (
                        <Tag style={{marginTop: 6}} color={color} key={group.id} onClick={()=>{
                          setGroupQuery(group.name);
                          document.getElementById('tbl').scrollIntoView()
                        }}>
                          <Copy text={group.name}/>
                        </Tag>
                      );
                    })}
                  </div>
                ),
              },
              {
                title: 'Created At',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: text => (<div style={{maxWidth: 250}}>
                  {new Date(text).toString().split(' ').splice(0, 4).join(' ')}
                </div>)
              },
            ]} dataSource={users} />
          </div>)}
        </div>
      </div>

      <div className="main-center-container">
        <div style={styles.groupsContainer}>
          <>
            <div style={styles.rightContainerHeader}>
              <span>GROUPS</span>
              <span className="rightContainerHeader-sub">Sorted by last activity</span>
            </div>
          </>
          <div
            id={'tbl'}>
            <input placeholder={'Filter by name'}  className="users_input" value={groupQuery} onChange={groupQueryHandler} />
            <DataTable
              theme="dark"
              expandableRows
              expandableRowsComponent={ExpandedGroupComponent}
              columns={[
                {
                  id: 'name',
                  name: 'Name',
                  selector: row => row.name,
                  sortField: 'name',
                  sortable: true,
                },
                {
                  id: 'members',
                  name: 'Members',
                  selector: row => row.chatType!=='DM'?row.members.length:row.chatType
                },
                {
                  id: 'counters.count',
                  name: 'Messages',
                  selector: row => row.counters?.count,
                },
                {
                  id: 'counters.count24',
                  name: 'Messages in last 24h',
                  selector: row => row.counters?.count24,
                },
                {
                  id: 'lastActivity',
                  name: 'Last Activity',
                  sortable: true,
                  sortField: 'lastActivity',
                  selector: row => new Date((row.lastActivity || row.updatedAt)).toString().split(' ').splice(0, 5).join(' ').slice(0, -3),
                },
                {
                  id: 'createdAt',
                  name: 'Created At',
                  sortable: true,
                  sortField: 'createdAt',
                  selector: row => new Date((row.createdAt)).toString().split(' ').splice(0, 4).join(' '),
                },
              ]}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationPerPage={200}
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10,50,100,200]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}

              onSort={handleSort}
              sortServer
              defaultSortFieldId={'lastActivity'}
              defaultSortAsc={false}
            />


          </div>
        </div>



      </div>
    </div>
  )
};

const styles = {

  groupsContainer: {
    padding: 10,
    backgroundColor: '#191C22',
    borderRadius: 25,
    width: '100%'
  },
  rightContainerHeader: {
    color: '#fefefe',
    padding: 20,
    paddingLeft: 15,
    fontSize: 15,
    fontWeight: 700,
    letterSpacing: 2,
    borderBottom: '1px solid rgb(40 43 47)',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },
  menuContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  menuItemText: {
    color: '#fefefe'
  }
};

