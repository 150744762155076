import { getCookie } from './cookies'

export class API {
  get baseURL() {
    return process.env.NODE_ENV==='development'?`http://localhost:9000`:`/api`
  }

  async put(path, body) {
    try {
      return await fetch(`${this.baseURL}/${path}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie('d-token')}`,
        },
        body: JSON.stringify(body)
      }).then(r => r.json())
    } catch (e) {
      console.log(e)
    }
  }

  async post(path, body) {
    return await fetch( `${this.baseURL}/${path}`,{
      method:"POST",
      headers: {
        Accept:"application/json",
        "Content-Type":"application/json",
        Authorization: `Bearer ${getCookie('d-token')}`,
      },
      body: JSON.stringify(body)}).then(r=>r.json())
  }

  async get(path) {
    try {
      return await fetch(`${this.baseURL}/${path}`,{
        method:"GET",
        headers: {
          Accept:"application/json",
          "Content-Type":"application/json",
          Authorization: `Bearer ${getCookie('d-token')}`
        }}).then(r=>r.json())
    } catch (e) {
      console.log(e)
    }
  }
  async delete(path) {
    return await fetch(`${this.baseURL}/${path}`,{
      method:"DELETE",
      headers: {
        Accept:"application/json",
        "Content-Type":"application/json",
        Authorization: `Bearer ${getCookie('d-token')}`
      }}).then(r=>r.json())
  }

}
